import styled from 'styled-components';

import { ReactComponent as BRACKET } from 'svgs/angleBracket.svg';

export const Button = styled.button`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  backdrop-filter: blur(5px);

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;

  aspect-ratio: 1/1;

  transition: background-color 0.3s ease;
`;

export const Bracket = styled(BRACKET)`
  color: inherit;
  width: 30%;
  transform: translateX(10%);
`;
