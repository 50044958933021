import { AnimatePresence, useInView } from 'framer-motion';
import React, { Fragment, FunctionComponent, useRef } from 'react';

import AnimatedTextWord from 'components/AnimatedTextWord/AnimatedTextWord';
import Button from 'components/Button/Button';
import useOverElement from 'hooks/useOverElement';
import { opacityMotionProps } from 'utils/styles/animations';
import { ColorNames, validFontCategories } from 'utils/styles/theme';

import * as S from './DoubleColorHeadline.styles';

export interface HeadlineSectionProps {
  id: number;
  headline: string;
  duration?: number;
  delay?: number;
  greyColor: boolean;
  newLine: boolean | string;
  accentColor?: string;
  accentDefaultColors?: string;
}

export interface DoubleColorHeadlineProps {
  headlines: HeadlineSectionProps[];
  button?: { href: string; label: string };
  gap?: string;
  renderTextAs?: string;
  size?: typeof validFontCategories[number];
}

const defaultProps: Partial<DoubleColorHeadlineProps> = {
  headlines: [
    { id: 0, headline: '', duration: 0.2, greyColor: false, newLine: false },
  ],
  gap: '0',
  renderTextAs: 'h2',
  size: 'heading2',
};

const DoubleColorHeadline: FunctionComponent<DoubleColorHeadlineProps> = ({
  headlines,
  button,
  gap,
  renderTextAs,
  size,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });

  useOverElement(ref);

  return (
    <S.Wrapper {...rest}>
      <S.AnimatedWrapper
        ref={ref}
        gap={gap}
        style={{
          opacity: isInView ? 1 : 0,
        }}
      >
        {headlines.map(
          ({
            id,
            headline,
            duration,
            delay,
            greyColor,
            newLine,
            accentColor,
            accentDefaultColors,
          }) => (
            <Fragment key={id + '-' + isInView}>
              <AnimatedTextWord
                as={renderTextAs || 'h2'}
                size={size}
                text={headline}
                duration={duration || 1}
                delay={delay || 0.2}
                color={
                  accentColor || accentDefaultColors
                    ? accentColor || accentDefaultColors
                    : greyColor
                    ? ColorNames.dustyGray
                    : ColorNames.white
                }
              />
              {newLine && <S.NewLine newLineHeight={newLine} />}
            </Fragment>
          )
        )}
      </S.AnimatedWrapper>

      {button && isInView && (
        <AnimatePresence mode="wait">
          <S.AnimatedWrapper
            {...opacityMotionProps({
              duration: 0.6,
              min: 0,
              delay: headlines[headlines.length - 1]?.delay || 0.2,
            })}
          >
            <Button {...button} />
          </S.AnimatedWrapper>
        </AnimatePresence>
      )}
    </S.Wrapper>
  );
};

DoubleColorHeadline.defaultProps = defaultProps;

export default DoubleColorHeadline;
