import styled from 'styled-components';

import BaseButton, { ButtonProps } from 'components/Button/Button';
import { MediaWrapper } from 'components/VideoBlock/VideoBlock.styles';
import { mediaDesktop } from 'utils/styles/responsive';
import { layout } from 'utils/styles/theme';

interface WrapperProps {}

const fullColumns = layout.columns.max;

export const Wrapper = styled.div<WrapperProps>`
  min-width: 100%;

  position: relative;
  flex: 1;
`;

export const ImageWrapper = styled.div`
  height: 100%;

  ${MediaWrapper} {
    height: 100%;
  }
`;

export const SlideOverlay = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;

  display: grid;
  place-items: center start;
`;

export const Container = styled.div`
  display: grid;
  grid-row-gap: 32px;
  grid-template-columns: [out-left] 16px [content] auto [out-right] 16px;
  place-items: center start;

  ${mediaDesktop(`
    padding: 0;
    grid-template-columns: [out-left] 92px repeat(
      ${fullColumns},
      [content] minmax(0px, 1fr)
    ) [out-right] 92px;
    grid-gap: 32px;
  `)};
`;

export const Question = styled.div`
  grid-column: content 1 / out-right;

  ${mediaDesktop(`
    grid-column: content 1 / content 8;
  `)};
`;

export const CaseStudyWrapper = styled.div`
  display: flex;
  align-items: center;
  row-gap: 16px;
  flex-wrap: wrap;

  grid-column: content 1 / out-right;

  h4 {
    margin-right: 20px;
  }

  ${mediaDesktop(`
    h4 {
      margin-right: 32px;
    }
  `)};
`;

export interface CarouselButtonProps extends ButtonProps {
  fixBottom: boolean;
}

export const CarouselButton = styled(BaseButton)<CarouselButtonProps>`
  ${({ fixBottom }) =>
    fixBottom &&
    `
    position: absolute;
    bottom: 52px;
    left: 50%;
    transform: translateX(-50%);
  `}
`;

export interface CarouselTextWrapperProps {
  fixCorner: boolean;
}

export const CarouselTextWrapper = styled.div<CarouselTextWrapperProps>`
  ${({ fixCorner }) =>
    fixCorner &&
    `
    position: absolute;
    inset: 4% 3%;
  `}
`;
