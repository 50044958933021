import React, { FunctionComponent } from 'react';

import { ReactComponent as SvgArrow } from 'svgs/arrow.svg';

import * as S from './AnimatedArrow.styles';

export interface AnimatedArrowProps {}

const defaultProps: Partial<AnimatedArrowProps> = {};

const AnimatedArrow: FunctionComponent<AnimatedArrowProps> = ({ ...rest }) => {
  return (
    <S.ArrowWrapper {...rest}>
      <SvgArrow />
    </S.ArrowWrapper>
  );
};

AnimatedArrow.defaultProps = defaultProps;

export default AnimatedArrow;
