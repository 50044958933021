import styled from 'styled-components';

import { ArrowWrapper } from 'components/AnimatedArrow/AnimatedArrow.styles';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

const Filter = styled.div`
  width: max-content;

  display: inline-flex;
  align-items: center;

  border-radius: 33px;

  cursor: pointer;

  &:not(:last-child) {
    padding: 0 0 12px;
  }

  a {
    display: flex;
    align-items: center;
  }

  ${ArrowWrapper} {
    width: 12px;
    height: 9px;
  }

  svg {
    width: 12px;
    height: 9px;
  }

  ${mediaDesktop(`
    padding: 11px 20px 12px 22px;
    transition: background-color 0.4s ease-in-out;

    &:not(:last-child) {
      padding: 11px 20px 12px 22px;
    }

    ${ArrowWrapper} {
      width: 14px;
      height: 11px;
    }

    svg {
      width: 14px;
      height: 11px;
      opacity: 0;
      transform: translateX(-150%);
      transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    }

    &:hover {
      background-color: ${colors.codGrayDarker};
      svg {
        opacity: 1;
        transform: translateX(0);
      }
    }
  `)};
`;

export default Filter;
