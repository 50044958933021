import styled from 'styled-components';

import { mediaDesktop } from 'utils/styles/responsive';

interface WrapperProps {}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;

  svg {
    height: 2px;
  }

  ${mediaDesktop(`
      svg {
        height: 3px;
      }
  `)}
`;
