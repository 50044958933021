import React, { FunctionComponent, memo, useRef } from 'react';

import * as S from './ProgressLine.styles';

export interface ProgressLineProps {
  progress: number;
  maxValue: number;
  isFilled?: boolean;
  stopped?: boolean;
}

const defaultProps: Partial<ProgressLineProps> = {
  progress: 0,
};

const ProgressLine: FunctionComponent<ProgressLineProps> = ({
  progress,
  maxValue,
  isFilled,
  stopped,
  ...rest
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const calcPercents = (min, max) => {
    return (min / max) * 100;
  };

  return (
    <S.Wrapper ref={wrapperRef} {...rest}>
      <svg
        fill="none"
        version="1.1"
        width={wrapperRef.current?.clientWidth || 297}
        viewBox={`0 0 ${wrapperRef.current?.clientWidth || 297} auto`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width={wrapperRef.current?.clientWidth || 297}
          height="100%"
          fill="#fff"
          opacity=".3"
        />
        <rect
          width="100%"
          height="100%"
          style={{
            fill: 'white',
            width:
              (isFilled
                ? 100
                : stopped
                ? 0
                : 100 - calcPercents(progress, maxValue)) + '%',
          }}
        />
      </svg>
    </S.Wrapper>
  );
};

ProgressLine.defaultProps = defaultProps;

export default memo(ProgressLine);
