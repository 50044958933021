import { motion } from 'framer-motion';
import styled from 'styled-components';

import ArrowButton from 'components/ArrowButton/ArrowButton';
import { mediaTablet } from 'utils/styles/responsive';
import { colors, layout } from 'utils/styles/theme';

interface WrapperProps {}

interface ProgressLinesWrapperProps {
  isHomePage?: boolean;
  columns?: number;
}

export const Wrapper = styled.section<WrapperProps>`
  height: 812px;
  position: relative;

  margin: 40px 0 120px;

  ${mediaTablet(`
    height: 852px;

    margin: 80px 0 238px;
  `)};
`;

export interface SlidesProps {
  fullWidth?: boolean;
}
export const Slides = styled(motion.div)<SlidesProps>`
  ${({ fullWidth }) => (fullWidth ? 'width' : 'height')}: 100%;
  display: flex;
  flex-wrap: nowrap;
`;

export const Motion = styled(motion.div)`
  min-width: 100%;

  position: relative;
  flex: 1;
`;

const progressLineStyles = {
  homePage: {
    paddings: { desktop: '28px 32px' },
    gaps: { mobile: '10px', desktop: '33px' },
  },
  default: {
    paddings: { desktop: '16px 32px' },
    gaps: { mobile: '11px', desktop: '33px' },
  },
};

export const ProgressLinesWrapper = styled.div<ProgressLinesWrapperProps>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  display: grid;
  ${({ isHomePage }) =>
    `
    grid-column-gap: ${
      isHomePage
        ? progressLineStyles['homePage']['gaps']['mobile']
        : progressLineStyles['default']['gaps']['mobile']
    };
  `}
  grid-template-columns: repeat(
    ${({ columns }) => columns || 1},
    minmax(1px, 1fr)
  );

  padding: 20px 16px;

  z-index: ${layout.zIndex.filter};

  ${({ isHomePage }) =>
    mediaTablet(`
    grid-column-gap: ${
      isHomePage
        ? progressLineStyles['homePage']['gaps']['desktop']
        : progressLineStyles['default']['gaps']['desktop']
    };
    padding: ${
      isHomePage
        ? progressLineStyles['homePage']['paddings']['desktop']
        : progressLineStyles['default']['paddings']['desktop']
    };
  `)}
`;

export const ControlsWrapper = styled.div`
  position: absolute;
  bottom: 29px;

  width: 100%;

  display: inline-flex;
  justify-content: space-between;
  pointer-events: none;

  padding: 20px 16px;

  ${mediaTablet(`
    padding: 16px 24px;
  `)};
`;

export const ButtonLeft = styled(ArrowButton)`
  background: ${colors.white}55;
  color: ${colors.white};

  :hover {
    background: ${colors.concrete};
    backdrop-filter: blur(5px);

    svg {
      color: ${colors.lighterMineShaft};
    }
  }
`;

export const ButtonRight = styled(ButtonLeft)`
  transform: rotate(180deg);
`;
