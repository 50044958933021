import React from 'react';

import { handleMouseEnter, handleMouseLeave } from 'utils/cursorGlobal';

import * as S from './ArrowButton.styles';

const ArrowButton: React.FunctionComponent<any> = ({ ...rest }) => {
  return (
    <S.Button
      {...rest}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <S.Bracket />
    </S.Button>
  );
};

export default ArrowButton;
