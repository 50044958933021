import { motion } from 'framer-motion';
import styled from 'styled-components';

interface WrapperProps {}

interface AnimatedWrapperProps {
  gap?: string;
}

interface NewLineProps {
  newLineHeight: string | boolean;
}

export const Wrapper = styled(motion.section)<WrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

export const AnimatedWrapper = styled(motion.div)<AnimatedWrapperProps>`
  display: flex;
  flex-wrap: wrap;

  gap: ${({ gap }) => gap};
`;

export const NewLine = styled.div<NewLineProps>`
  flex-basis: 100%;
  height: ${({ newLineHeight }) =>
    typeof newLineHeight === 'string' ? newLineHeight : 0};
`;
