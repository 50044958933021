import { wrap } from '@motionone/utils';
import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import ProgressLine from 'components/ProgressLine/ProgressLine';
import useOverElement from 'hooks/useOverElement';
import { Timer } from 'utils';
import { ROUTES } from 'utils/routes';
import {
  MediaCMS,
  MediaType,
  VideoTypesCMS,
} from 'utils/sharedStrapiQueries/sharedTypes';

import { carouselAnimations } from './Carousel.animations';
import CarouselSlide from './CarouselSlide/CarouselSlide';

import * as S from './Carousel.styles';

export interface CarouselSlidesProps extends VideoTypesCMS {
  media: MediaCMS;
  mediaType: MediaType;
  question?: string;
  clientName: string;
  caseStudy: { slug: string };
  buttonText: string;
  videoErrorMessage?: string;
}

export interface CarouselProps {
  carouselSlides: CarouselSlidesProps[];
  duration: number;
  showPagination?: boolean;
  slidesFullWidth?: boolean;
}

const defaultProps: Partial<CarouselProps> = {
  carouselSlides: [
    {
      media: {
        url: '',
      },
      mediaType: 'image',
      question: '',
      clientName: '',
      caseStudy: { slug: '' },
      buttonText: '',
      source: 'self_hosted',
      autoplay: true,
      manualPlay: false,
      mute: false,
      withSound: false,
    },
  ],
  duration: 2,
  showPagination: false,
};

const Carousel: FunctionComponent<CarouselProps> = ({
  carouselSlides,
  duration,
  showPagination,
  slidesFullWidth,
  ...rest
}) => {
  const { route } = useRouter();
  const timer = useRef(null);
  const interval = useRef(null);

  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [hovered, setHovered] = useState(false);

  const [ref] = useOverElement();

  const setProperSlide = value =>
    setCurrentSlide(wrap(0, carouselSlides.length, value));

  const resetSlider = () => {
    setCount(0);
    clearInterval(interval.current);

    timer.current = new Timer(() => {
      setProperSlide(currentSlide + 1);
    }, duration * 1000);

    interval.current = setInterval(() => {
      setCount(timer.current.getCurrentTime());
    }, 10);
  };

  const pause = () => {
    timer.current.pause();
  };

  const play = () => {
    timer.current.resume();
  };

  const changeSlide = value => {
    setProperSlide(value);
    resetSlider();
  };

  useEffect(() => {
    resetSlider();
  }, [currentSlide]);

  const slides = carouselSlides.map((props, index) => (
    <S.Motion
      key={index + `${currentSlide}`}
      variants={carouselAnimations}
      animate="enter"
      initial="initial"
      exit="exit"
    >
      <CarouselSlide {...props} hideCaseStudyButton={showPagination} />
    </S.Motion>
  ));

  const pagination = (
    <S.ControlsWrapper>
      {currentSlide > 0 ? (
        <S.ButtonLeft onClick={() => changeSlide(currentSlide - 1)} />
      ) : (
        <span />
      )}
      {currentSlide < carouselSlides.length - 1 ? (
        <S.ButtonRight onClick={() => changeSlide(currentSlide + 1)} />
      ) : (
        <span />
      )}
    </S.ControlsWrapper>
  );

  const onMouseEnter = () => {
    if (!showPagination) return;
    pause();
    setHovered(true);
  };

  const onMouseLeave = () => {
    if (!showPagination) return;
    play();
    setHovered(false);
  };

  return (
    <S.Wrapper
      {...rest}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <S.Slides ref={ref} fullWidth={slidesFullWidth}>
        <AnimatePresence mode="popLayout">
          {slides[currentSlide]}
        </AnimatePresence>
      </S.Slides>

      {showPagination && hovered && pagination}

      {carouselSlides.length > 1 && (
        <S.ProgressLinesWrapper
          columns={carouselSlides.length}
          isHomePage={ROUTES.HOME === route}
        >
          {Array(carouselSlides.length)
            .fill(carouselSlides.length)
            .map((n, index) => (
              <ProgressLine
                key={n + index}
                progress={count}
                maxValue={(duration || 4) * 1000}
                isFilled={index < currentSlide}
                stopped={index !== currentSlide}
              />
            ))}
        </S.ProgressLinesWrapper>
      )}
    </S.Wrapper>
  );
};

Carousel.defaultProps = defaultProps;

export default Carousel;
