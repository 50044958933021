export const carouselAnimations = {
  initial: {
    opacity: 0,
    transition: {
      duration: 0.6,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.6,
    },
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 0.6,
    },
  },
};
