import { motion } from 'framer-motion';
import styled from 'styled-components';

import { mediaDesktop } from 'utils/styles/responsive';

interface WrapperProps {}

export const ArrowWrapper = styled(motion.div)<WrapperProps>`
  display: block;

  margin-left: 8px;

  overflow: hidden;

  ${mediaDesktop(`
    margin-left: 12px;
  `)};
`;
