import { AnimatePresence, motion } from 'framer-motion';
import React, { FunctionComponent, useEffect, useState } from 'react';

import Text from 'components/Text/Text';
import { ColorNames, validFontCategories } from 'utils/styles/theme';

export interface AnimatedTextWordProps {
  text: string;
  size: typeof validFontCategories[number];
  as?: string;
  color?: ColorNames | string;
  delay?: number;
  timeout?: number;
  duration?: number;
}

const defaultProps: Partial<AnimatedTextWordProps> = {
  text: '',
  size: 'body',
  as: 'h4',
  color: ColorNames.white,
  delay: 0.2,
  duration: 1,
};

const AnimatedTextWord: FunctionComponent<AnimatedTextWordProps> = ({
  text,
  size,
  as,
  color,
  delay,
  timeout,
  duration,
  ...rest
}) => {
  const [animateStart, setAnimateStart] = useState<boolean>(!timeout);
  const words = text.split(' ');

  useEffect(() => {
    if (timeout) {
      const animateTimeout = setTimeout(() => {
        setAnimateStart(true);
      }, timeout * 1000);

      return () => clearTimeout(animateTimeout);
    }
  }, [timeout]);

  const child = {
    visible: (i = 1) => ({
      opacity: 1,
      x: 0,
      transition: {
        duration: duration,
        damping: 12,
        stiffness: 100,
        delay: delay * i,
      },
    }),
    hidden: {
      opacity: 0,
      x: 20,
      transition: {
        duration: duration,
        damping: 12,
        stiffness: 100,
      },
    },
  };

  return (
    <AnimatePresence mode="wait">
      {animateStart ? (
        words.map((word, i) => (
          <motion.div
            key={i}
            initial="hidden"
            animate="visible"
            custom={i + 1}
            variants={child}
            {...rest}
          >
            <Text as={as} size={size} text={word + '\u00A0'} color={color} />
          </motion.div>
        ))
      ) : (
        <motion.div initial="hidden" variants={child} {...rest}>
          <Text as={as} size={size} text={text} color={color} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

AnimatedTextWord.defaultProps = defaultProps;

export default AnimatedTextWord;
